<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-61.mp3"),
      },
      timeStampList: [7, 10.5, 14.2, 25, 28.7, 31],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "yī, èr, sān, sì, wǔ, liù, qī,",
            chineseWords: "一、二、三、四、五、六、七，",
          },
          {
            id: 2,
            spelling: " Yí ge xīngqī yǒu qī tiān.",
            chineseWords: "一个星期有七天。",
          },
          {
            id: 3,
            spelling:
              "Xīngqīyī, Xīngqī'èr, Xīngqīsān, Xīngqīsì, Xīngqīwǔ, Xīngqīliù,",
            chineseWords: "星期一、星期二，星期三、星期四，星期五、星期六。",
          },
          {
            id: 4,
            spelling: "Jīntiān Xīngqī jǐ?",
            chineseWords: "今天星期几？",
          },
          {
            id: 5,
            spelling: "Jīntiān Xīngqītiān!",
            chineseWords: "今天星期天！",
          },
        ],
      },
    };
  },
};
</script>
